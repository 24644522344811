import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Profile from "../components/profile"

import Seo from "../components/seo"


const Blogpost = ({ data, pageContext, location }) => {
    return (
        <Layout>
            <Seo pagetitle={data.contentfulBlogPost.title}
                pagedesc={data.contentfulBlogPost.content.childMarkdownRemark.excerpt}
                pagepath={location.pathname}
                blogimg={`https:${data.contentfulBlogPost.eyecatch.file.url}`}
                pageimgw={data.contentfulBlogPost.eyecatch.file.details.image.width}
                pageimgh={data.contentfulBlogPost.eyecatch.file.details.image.height}
            />
            <div className="wrapper2">
                <section className="blog">
                    <ul className="blog__category">
                        {data.contentfulBlogPost.category.map(cat => (
                            <Link to={`/category/${cat.categorySlug}/`} className="blog__categoryLink">
                            <li className="blog__categoryItem" key={cat.id}>
                                {cat.category}
                            </li>
                            </Link>
                        ))}
                    </ul>
                    <h2 className="blog__title">{data.contentfulBlogPost.title}</h2>
                    <p className="blog__date"><time dateTime={data.contentfulBlogPost.publishDate}>{data.contentfulBlogPost.publishDateJP}</time></p>
                    <p className="blog__picture"><GatsbyImage image={data.contentfulBlogPost.eyecatch.gatsbyImageData} alt={data.contentfulBlogPost.eyecatch.description} /></p>
                    <div className="blog__content" dangerouslySetInnerHTML={{
                        __html: data.contentfulBlogPost.content.childMarkdownRemark.html,
                    }} />
                </section>

                {pageContext.previous && (
                    <div class="previous">
                        <Link to={`/${pageContext.previous.slug}/`} rel="prev" class="previous__link">
                            <div class="previous__arrow">
                            </div>
                            <p class="previous__text">{pageContext.previous.title}</p>
                        </Link>
                    </div>
                )}

                {pageContext.next && (
                    <div class="next">
                        <Link to={`/${pageContext.next.slug}/`} rel="next" class="next__link">
                            <p class="next__text">{pageContext.next.title}</p>
                            <div class="next__arrow">
                            </div>
                        </Link>
                    </div>
                )}

                <Profile />
            </div>
        </Layout>
    )
}

export default Blogpost

export const query = graphql`
 query($id: String!) {
    contentfulBlogPost(id: { eq: $id }) {
        title
        publishDateJP: publishDate(formatString: "YYYY年MM月DD日")
        publishDate
        category {
            category
            categorySlug
            id
        }
        eyecatch {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR)
            description
            file {
                details {
                    image {
                        width
                        height
                    }
                }
                url
            }
        }
        content {
            childMarkdownRemark {
                excerpt(truncate: true, pruneLength: 70)
                html
            }
        }
    }
 }
`